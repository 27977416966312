.root {
    margin: auto;
    text-align: center;
    width: 40%;
    top: 64px;
    margin-bottom: 20vh;
}

@media only screen and (max-width: 1000px) {
    .root {
        width: 80%
    }
}