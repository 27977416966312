  .root {
    margin: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
  }

  .cardBody {
    padding-top: 16px;
    padding-bottom: 32px;
    width: 80%;
    margin: auto;
  }

  .btmEditBtn {
    margin-top: 32px;
    margin-left: 6px;
    margin-right: 6px;
  }

  .editBtn {
    width: 256px;
    margin: auto;
    margin-top: 32px;
  }

  .innerCardBody {
    width: 75%;
    margin-bottom: 0;
    margin-top: 0;
    margin: auto;
  }

  .avatarLogo {
    margin: auto;
    width: 8rem;
    /* height: 8rem !important; */
    margin-right: 2rem;
  }

  .jobContent {
    font-size: 0.9rem;
    margin: 0;
    text-align: justify;
  }

  .educationElem p {
    margin: 0
  }

  .avatarMobileLogo {
    width: 0px;
    height: 0px;
    display: none;
  }

  .centerOnMobile {
    text-align: left;
    margin: 0;
  }

  .editBtnInner {
    margin-top: 8px;
    margin-left: 6px;
    margin-right: 6px;
  }

  .summaryPad {
    padding-left: 96px;
    padding-right: 96px;
  }

  .skill {
    padding: 8px;
    font-weight: 600
  }

  .noGreenLink:any-link {
    color: white;
    text-decoration: underline;
  }

  .contactTag {
    padding: 16px;
    /* max-width: 600px; */
    margin: auto;
    margin-bottom: 4px;
    text-align: center;
  }

  .experienceElem {
    color: white;
    text-align: center;
    margin: auto;
  }

  .educationElem {
    color: white;
    text-align: center;
    margin: auto;
  }

  .addSkillBox {
    width: 50%;
    margin: auto
  }

  .awardElem {
    color: white;
    text-align: center;
    margin: auto;
  }

  @media only screen and (max-width: 800px) {
    .cardBody {
      width: 90%;
    }
    .innerCardBody {
      width: 90%;
    }
    .avatarLogo {
      width: 0px;
      height: 0px;
      margin-right: 0;
      display: none;
    }
    .jobContent {
      text-align: justify;
      font-size: 0.75rem;
    }
    .avatarContent h4 {
      text-align: center;
    }
    .avatarContent {
      margin-left: 0;
    }
    .avatarMobileLogo {
      width: 6rem;
      height: unset;
      margin-bottom: 1.3rem;
      display: block;
    }
    .centerOnMobile {
      text-align: center;
    }
    .skill {
      font-size: 1rem;
      margin: 4px;
    }
    .summaryPad {
      padding-left: 0;
      padding-right: 0px;
    }
    .addSkillBox {
      width: 90%
    }
  }