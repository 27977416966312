.root {
  margin: auto;
  padding: 20px;
  text-align: center;
  /* z-index: 10;
  position: fixed;
  width: 100%;
  left: 0; */
}

.cardBody {
  padding-top: 16px;
  padding-bottom: 32px;
  width: 80%;
  margin: auto;
}
.innerCardBody {
  width: 75%;
  margin-bottom: 0;
  margin-top: 0;
  margin: auto;
  padding-bottom: 32px;
}

@media only screen and (max-width: 800px) {
  .cardBody {
    width: 90%;
  }
  .innerCardBody {
    width: 90%;
  }
}
