body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hoverLarge {
  transition: transform 0.2s;
}

.hoverLarge:hover {
  transform: scale(1.1);
}

.hoverLarge12 {
  transition: transform 0.2s;
}

.hoverLarge12:hover {
  transform: scale(1.2);
}

.hoverLarge105 {
  transition: transform 0.2s;
}

.hoverLarge105:hover {
  transform: scale(1.05) !important;
}

.highlightColor {
  color: #6fc84f;
}

.highlightColorLight {
  color: #95d77e;
}

*:any-link {
  color: #6fc84f;
}

.modal-content {
  background-color: var(--ifm-color-gray-900) !important;
}

.ant-popover-inner {
  background-color: var(--ifm-color-gray-1000) !important;
}

.ant-popover-message-title {
  color: white !important;
}
.ant-popover-inner {
  background-color: var(--ifm-color-gray-800) !important;
  border-style: solid;
  border-color: var(--ifm-color-gray-800);
  border-width: 1px;
}
.ant-popover-placement-top .ant-popover-arrow {
  background-color: var(--ifm-color-gray-800) !important;
  border-style: solid;
  border-right-color: var(--ifm-color-gray-800) !important;
  border-bottom-color: var(--ifm-color-gray-800) !important;
  border-width: 1px;
}
.ant-popover-placement-bottom .ant-popover-arrow {
  background-color: black;
  border-style: solid;
  border-top-color: var(--ifm-color-gray-800) !important;
  border-left-color: var(--ifm-color-gray-800) !important;
  border-width: 1px;
}

.ant-modal-content,
.ant-modal-header,
.ant-modal-title,
.ant-modal-close-icon {
  background-color: var(--ifm-color-gray-1000);
  color: white;
}

.invalid-feedback {
  color: red !important;
  font-size: 100% !important;
}

.form-label {
  margin-top: 12px;
}

.hidden {
  visibility: hidden;
}

.all-scroll {
  cursor: all-scroll;
}

.grab {
  cursor: -webkit-grab;
  cursor: grab;
}
.grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.aspect-ratio-box-16-9 {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.aspect-ratio-box-inside-16-9 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}