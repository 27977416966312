.loader {
  width: 15vw;
}

@media only screen and (max-width: 1000px) {
  .loader {
    width: 50vw;
  }
}
.mobile-center-full-left {
  text-align: left;
}
@media only screen and (max-width: 800px) {
  .mobile-center-full-left {
    text-align: center;
  }
}
.margin--auto {
  margin: auto;
}
.ant-message-notice-content {
  background-color: black !important;
  font-size: 1rem;
}
.ant-message-custom-content {
  color: white;
}
/* .ant-modal-body {
  border: 1px solid var(--ifm-color-gray-800);
  border-radius: 8px;
} */
.wide-for-mobile {
  margin: auto;
  width: 50vw;
}
@media only screen and (max-width: 1000px) {
  .wide-for-mobile {
    width: 80vw;
  }
}

/* .navbar-collapse * {
  text-align: right
}

.dropdown {
  margin-top: 8px;
  display: block;
}

.dropdown .ant-avatar {
  margin-top: 12px;
} */

.navbar-toggler {
  visibility: hidden;
}


.card {
  border: none
}