.cardRoot {
    width: 50vw;
    padding-top: 8px;
    margin: auto;
}

.cardBody {
    width: 70%;
    margin: auto;
}

.sectionOrderCard {
    width: 70%;
    margin: auto
}

@media only screen and (max-width: 1000px) {
    .cardRoot {
        width: 90vw;
    }
    .cardBody {
        width: 90%;
    }
    .sectionOrderCard {
        width: 90%;
    }
}
