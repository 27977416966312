.heroProjectTagline {
  color: #fff;
  margin-bottom: 0;
  font-size: 60px;
}

.heroLogo {
  animation-duration: 2s;
  animation-name: jackInTheBox;
  margin-top: 24px;
}

.featureImage {
  max-width: 60%;
  height: 20vh;
  margin: 0 auto;
}

.featureHeading {
  font-size: var(--ifm-h3-font-size);
  padding-top: 1rem;
}

.section {
  padding: 72px 0;
}

.generateBtn {
  margin-top: 24px;
}

.profilePic {
    left: 0;
    object-fit: cover;
}

@media only screen and (max-width: 1000px) {
  .generateBtn {
    text-align: center;
    margin-top: 30px;
  }
  .heroLogo {
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .heroProjectTagline {
    font-size: 35px;
    text-align: center;
  }
  .featureImage {
    margin-top: 30px;
  }
}
