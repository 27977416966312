

.root h1 {
    font-size: 4rem;
}

.root {
    padding-top: 32px;
    text-align: center;
    padding-bottom: 32px;
}