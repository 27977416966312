.root {
  margin: auto;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
}

.root h2 {
  font-size: 30px;
}

.root h5 {
  font-size: 1.2rem;
  font-weight: 400;
  margin: 8px;
}
.root h6 {
  font-size: 14px;
  font-weight: 400;
  margin: 12px;
}

.root button {
  font-weight: 400;
  font-size: 18px;
  margin: 16px;
  /* margin-right: 8px;
  margin-left: 8px;
   margin-top: 16px; 
  margin-bottom: 16px; */
}

.root .alert {
  font-weight: 400;
  font-size: 18px;
  margin: 16px;
  /* margin-right: 8px;
  margin-left: 8px;
   margin-top: 16px; 
  margin-bottom: 16px; */
}

/* .hostingNameInput {
  border: 10px solid rgb(50, 156, 46);
  color: red;
  outline: red;
} */
